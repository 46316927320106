import React from "react";
import agencement from "../../Images/ground.webp";
import bois from "../../Images/bois.webp";
import coupe from "../../Images/coupe.webp";
import mobile_money from "../../Images/mobile.webp";
import sur_mesure from "../../Images/service_sur_mesure.webp";
import livraison_domicile from "../../Images/livraison.webp";
import mobilier from "../../Images/mobilier.webp";
import fenêtre from "../../Images/fenêtre.webp";
import porte from "../../Images/porte.webp";
import vente_bois from "../../Images/vente-bois.webp";
import commandes from "../../Images/commandes.webp";
import useStyles from "../Layout/styles";
import {
  Box,
  Stack,
  Typography,
  Divider,
  Container,
  List,
  ListItem,
} from "@mui/material";
import { ImageBar, ImgListItem } from "../customed";
import "./services.css";

const Services = () => {
  const classes = useStyles();

  return (
    <Stack
      id="services"
      padding={{
        xs: "0rem 1.5em 1.5em 1.5em",
        sm: "1rem 2rem",
        md: "1rem 2rem",
        lg: "1rem 2rem",
      }}
      backgroundColor="#FDFBF9"
      fontWeight="bold"
    >
      <Divider textAlign="left">
        <Typography m={2} variant="h6">
          Nos services
        </Typography>
      </Divider>
      <Stack
        display={"flex"}
        direction={{ xs: "column", sm: "row", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        padding={{ md: "0rem 2rem" }}
        mb={{ xs:0, sm: 5, md: 0 }}
        spacing={{ sm: 2, md: 1, lg: 10 }}
      >
        <Box
          m={{ xs: 1, sm: 1 }}
          width={{ xs: "20rem", sm: "35rem", md: "45rem", lg: "51rem" }}
          alignItems={{ xs: "center", sm: "center", lg: "center" }}
        >
          <Typography fontSize={{ xs: 18, sm: 15, md: 18, lg: 20 }}>
            Les artisans menuisiers de Yohann Diffusion mettent leur
            savoir-faire et leurs compétences au service de tous vos projets de
            menuiserie. Yohann Diffusion vous accompagne dans vos projets
            d'agencements tels que l'agencement de cuisine, de dressing, de
            salle de bains mais aussi l'aménagement de magasins, de restaurants
            et de bureaux ainsi que pour vos projets de menuiserie extérieure
            (portes, fenêtres) et dans vos travaux de portails et clôtures.
          </Typography>
        </Box>
        <Box className={classes.visible}>
          <img
            id="bois"
            src={bois}
            alt="illustration menuiserie"
            width="auto"
            height="275"
            style={{ borderRadius: "10px 100px / 120px" }}
          />
        </Box>
      </Stack>
      <Container padding={{ xs: 0.5, sm: 3, md: 0.5, lg: 0 }}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 6, sm: 2, md: 3, lg: 7 }}
          margin={{ xs: "0rem 1rem", sm: "0.5rem 0rem", md: "3rem 0rem" }}
          alignItems={"center"}
          justifyContent={"center"}
          className="reveal revealLeft"
        >
          <List
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={mobilier}
                  className="image"
                  alt="Mobilier"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Mobilier"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={fenêtre}
                  className="image"
                  alt="Fenêtres"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Fenêtres"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={porte}
                  className="image"
                  alt="Portes"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Portes"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={sur_mesure}
                  className="image"
                  alt="Sur mesure"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Service sur mesure"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={agencement}
                  className="image"
                  alt="Agencement intérieur"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Agencement intérieur"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
          </List>
        </Stack>
      </Container>
      <Container padding={{ xs: 0.5, sm: 1, md: 1, lg: 0 }}>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 6, sm: 2, md: 1, lg: 7 }}
          margin={{
            xs: "0rem 1rem",
            sm: "1rem 0rem 2rem 0em",
            md: "0rem 0rem 3em 0em",
          }}
          alignItems={"center"}
          justifyContent={"center"}
          className="reveal revealRight"
        >
          <List
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            {" "}
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  className="image"
                  src={vente_bois}
                  loading="lazy"
                  alt="vente de bois"
                />
                <ImageBar
                  position="below"
                  title="Vente de bois"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={coupe}
                  className="image"
                  alt="Découpe"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Découpe immédiate"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  className="image"
                  src={mobile_money}
                  loading="lazy"
                  alt="mobile money"
                />
                <ImageBar
                  position="below"
                  title="Paiement à distance"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>{" "}
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={livraison_domicile}
                  className="image"
                  alt="Livraison possible"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Livraison possible"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>{" "}
            </ListItem>
            <ListItem sx={{ padding: 4 }}>
              <ImgListItem>
                <img
                  src={commandes}
                  className="image"
                  alt="Commande de machine possible"
                  loading="lazy"
                />
                <ImageBar
                  position="below"
                  title="Commande possible"
                  sx={{ paddingTop: 2 }}
                />
              </ImgListItem>{" "}
            </ListItem>
          </List>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Services;
