import React from "react";
import machine_fufu from "../../Images/fufu.webp";
import combinee from "../../Images/combinee.webp";
import scie from "../../Images/scie.webp";
import raboteuse from "../../Images/raboteuse.webp";
import machine_saka_saka from "../../Images/machine_saka_saka.webp";
import machine_arachide from "../../Images/machine_arachide.webp";
import machine_mais from "../../Images/machine_mais.webp";
import mortaiseuse from "../../Images/mortaiseuse.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./materiel.css";
import { CardContt, CardMed, Divide } from "../customed";
import { settings } from "../../settings";
import { Card, Stack, Typography } from "@mui/material";

const Materiel = () => {
  return (
    <Stack
      id="materiel"
      padding={{
        xs: "1rem 1.5rem",
        sm: "1rem 3em",
        md: "1rem 3em",
        lg: "1rem 4rem",
      }}
      backgroundColor="#800000"
      color={"#f8f8ff"}
    >
      <Divide textAlign="left">
        <Typography m={1} variant="h6">
          Notre matériel
        </Typography>
      </Divide>
      <Typography
        color={"#f8f8ff"}
        p={{ xs: "1rem 0em", sm: "1rem", md: "1rem 1rem" }}
        fontSize={{ xs: 16, sm: 15, md: 16, lg: 18 }}
      >
        Yohann Diffusion dispose de plusieurs machines de menuiserie qui nous
        permettent de travailler rapidement et efficacement afin de vous
        proposer le meilleur service possible. Nous avons également plusieurs
        machines agroalimentaires (machines à foufou, saka-saka, maïs, pâte
        d'arachide), ce qui nous permet de diversifier notre activité et notre
        clientèle.
      </Typography>
      <div>
        <Slider {...settings} className="reveal revealUp">
          <div>
            <Card id="card">
              <CardMed component="img" alt="Combinée" image={combinee} />
              <CardContt>
                <Typography gutterBottom component="div">
                  Combinée à bois multifonction
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Notre combinée est une machine qui réunit toutes les fonctions
                  essentielles à nos travaux de menuiserie.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed component="img" alt="Raboteuse" image={raboteuse} />
              <CardContt>
                <Typography gutterBottom component="div">
                  Raboteuse
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Notre raboteuse nous permet d'éliminer les résidus
                  superficiels laissés par la coupe et rend la surface du bois
                  plus lisse.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed component="img" alt="Mortaiseuse" image={mortaiseuse} />
              <CardContt>
                <Typography gutterBottom component="div">
                  Mortaiseuse
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Notre mortaiseuse qui nous sert pour la réalisation des
                  mortaises.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed component="img" alt="Scie" image={scie} />
              <CardContt>
                <Typography gutterBottom component="div">
                  Scie
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Une scie pour débiter le bois brut.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed
                component="img"
                alt="Machine pâte d'arachide"
                image={machine_arachide}
              />
              <CardContt>
                <Typography gutterBottom component="div">
                  Machine pâte d'arachide
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Nous avons également plusieurs machines agroalimentaires.
                  Voici notre machine pour confectionner la pâte d'arachide.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed
                component="img"
                alt="Machine foufou"
                image={machine_fufu}
              />
              <CardContt>
                <Typography gutterBottom component="div">
                  Machine pour le foufou
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Une machine pour broyer le foufou.
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed component="img" alt="Combinée" image={machine_mais} />
              <CardContt>
                <Typography gutterBottom component="div">
                  Machine pour le maïs
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Une machine pour broyer le maïs
                </Typography>
              </CardContt>
            </Card>
          </div>
          <div>
            <Card id="card">
              <CardMed
                component="img"
                alt="Combinée"
                image={machine_saka_saka}
              />
              <CardContt>
                <Typography gutterBottom component="div">
                  Machine pour le saka-saka
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Une machine pour piler le saka-saka
                </Typography>
              </CardContt>
            </Card>
          </div>
        </Slider>
      </div>
    </Stack>
  );
};

export default Materiel;
