import React from "react";
import logo from "../../../Images/logo-yohann-diffusion.png";
import { Box, Stack, Link } from "@mui/material";
import { Phone, LocationOn, Mail } from "@mui/icons-material";
import { TypoHeader } from "../../customed";
import "animate.css";
import "animate.css/animate.min.css";
import "./header.css";
import Share from "../../Share/share";

const Header = () => {
  return (
    <Box direction={{ xs: "column", sm: "row", md: "row" }}>
      <Box id="bloc0" className="revealLogoLeft" sx={{ float: "left" }}>
        <Link href="/">
          <img id="logo" src={logo} alt="Logo Yohann Diffusion" />
        </Link>
      </Box>
      <Box id="bloc" sx={{ float: "right" }}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <div className="revealLightSpeedInRight">
            <Phone fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>+242 06 629 86 61</TypoHeader>
          </div>
        </Stack>
        <Stack
          direction="row"
          display="flex"
          marginTop={1}
          alignItems={"center"}
          spacing={1}
        >
          <div className="revealLightSpeedInRight">
            <Mail fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>
              <a
                target="_blank"
                rel="noopener"
                href="mailto:yohanndiffusion242@gmail.com"
              >
                yohanndiffusion242@gmail.com
              </a>
            </TypoHeader>
          </div>
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          display="flex"
          marginTop={1}
          alignItems={"center"}
        >
          <div className="revealLightSpeedInRight">
            <LocationOn fontSize={"small"} />
          </div>
          <div>
            <TypoHeader>Kintele, Brazzaville</TypoHeader>
          </div>
        </Stack>
        <Stack direction="row" alignItems={"center"} pt={1}>
          <Share />
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
