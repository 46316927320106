import { styled } from "@mui/material/styles";
import {
  Button,
  CardContent,
  ImageListItem,
  Typography,
  CardMedia,
  Fab,
  Paper,
  Card,
  Divider,
  ImageListItemBar,
} from "@mui/material";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to="mailto:yohanndiffusion242@gmail.com"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};
const Divide = styled(Divider)(() => ({
  "&::before, &::after": {
    borderColor: "#f8f8ff",
  },
}));

const SubmitButton = styled(Fab)((props) => ({
  height: "3em",
  textTransform: "capitalize",
  minWidth: "7em",
  [props.theme.breakpoints.up("xs")]: {
    float: "right",
    margin: "0.5rem",
    size: "small",
    backgroundColor: "black",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#800000",
      color: "#FFF",
    },
  },
  [props.theme.breakpoints.up("sm")]: {
    float: "right",

    backgroundColor: "black",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#800000",
      color: "#FFF",
    },
  },
}));

const ImgListItem = styled(ImageListItem)((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    height: "11rem!important",
    width: "11rem!important",
    borderRadius: "100%",
    justifyContent: "center!important",
    textAlign: "center!important",
  },

  [props.theme.breakpoints.up("xs")]: {
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    height: "11rem!important",
    width: "11rem!important",
    borderRadius: "100%",
    justifyContent: "center!important",
    textAlign: "center!important",
  },
  [props.theme.breakpoints.up("sm")]: {
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    height: "6rem!important",
    width: "6rem!important",
    borderRadius: "100%",
    textAlign: "center",
  },
  [props.theme.breakpoints.up("md")]: {
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    height: "11rem!important",
    width: "11rem!important",
    borderRadius: "100%",
    textAlign: "center",
  },
}));

const CardTar = styled(Card)((props) => ({
  [props.theme.breakpoints.up("xs")]: {
    width: "14rem",
    height: "3.5rem",
    padding: 5,
    backgroundColor: "#ba7538",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  [props.theme.breakpoints.up("sm")]: {
    backgroundColor: "#ba7538",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    alignItems: "center",
  },
  [props.theme.breakpoints.up("md")]: {
    backgroundColor: "#ba7538",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  [props.theme.breakpoints.up("lg")]: {
    backgroundColor: "#ba7538",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
}));

const Pap = styled(Paper)(() => ({
  backgroundColor: "#FDFBF9",
  borderRadius: "2em",
}));

const ImageBar = styled(ImageListItemBar)(() => ({
  background:
    "linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%)",
  color: "black",
}));

const CardContt = styled(CardContent)((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    fontSize: 5,
    alignContent: "center !important",
    textAlign: "center !important",
  },
  [props.theme.breakpoints.up("sm")]: {
    fontSize: 5,
    alignContent: "center !important",
    textAlign: "center !important",
  },
  [props.theme.breakpoints.between("sm", "md")]: {
    fontSize: 10,
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  [props.theme.breakpoints.up("md")]: {
    fontSize: 10,
    textAlign: "center",
  },
}));

const CardMed = styled(CardMedia)((props) => ({
  [props.theme.breakpoints.up("xs")]: {
    height: "25.5rem",
    width: "100%",
  },
  [props.theme.breakpoints.up("sm")]: {
    height: "25rem",
    width: "100%",
  },
  [props.theme.breakpoints.up("md")]: {
    height: "27rem",
    width: "100%",
  },
  [props.theme.breakpoints.up("lg")]: {
    height: "33rem",
    width: "100%",
  },
}));

const DeleteButton = styled(Button)(() => ({
  float: "right",
  padding: "0.7rem",
  fontSize: "0.6rem",
  backgroundColor: "#FFA500",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "lightGray",
    color: "#FFF",
  },
}));

const Title = styled(Typography)(() => ({
  margin: "2rem",
  display: "flex",
  justifyContent: "center",
  textTransform: "uppercase",
  fontSize: "1.3rem",
  fontFamily: "monospace",
}));

const TypoHeader = styled(Typography)((props) => ({
  [props.theme.breakpoints.up("xs")]: {
    fontSize: 14,
    fontWeight: "bold",
    margin: 1,
    alignContent: "center",
  },
  [props.theme.breakpoints.up("md")]: {
    fontSize: 16,
    margin: 1,
    fontWeight: "bold",
    alignContent: "center",
  },
}));

const LinkFooter = styled(Typography)((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    color: "black",
    // textTransform: "uppercase",
    textDecoration: "none",
    fontSize: 12,
  },
  [props.theme.breakpoints.up("xs")]: {
    fontSize: 15,
    color: "black",
    alignContent: "center",
    // textTransform: "uppercase",
  },
}));

const NavSmallLink = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: "Grey",
  fontFamily: "monospace",
  textDecoration: "none",
  justifyContent: "center",
}));

export {
  SubmitButton,
  LinkFooter,
  DeleteButton,
  Title,
  TypoHeader,
  ButtonMailto,
  ImgListItem,
  CardContt,
  CardMed,
  NavSmallLink,
  ImageBar,
  Divide,
  Pap,
  CardTar,
};
