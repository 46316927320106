import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notFoundImage: {
    [theme.breakpoints.down("sm")]: {
      width: "12rem",
      padding: "2rem",
      margin: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20rem",
      padding: "4rem",
      margin: "3rem",
    },
  },
  shadow: {
    [theme.breakpoints.down("sm")]: {
      boxShadow: "5px 2px 2px 1px rgba(0, 0, 255, .2)",
      height: "10rem!important",
      width: "10rem!important",
      borderRadius: "100%",
    },

    [theme.breakpoints.up("xs")]: {
      boxShadow: "5px 2px 2px 1px rgba(0, 0, 255, .2)",
      height: "11rem!important",
      width: "11rem!important",
      borderRadius: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      boxShadow: "5px 2px 2px 1px rgba(0, 0, 255, .2)",
      height: "8.5rem!important",
      width: "8.5rem!important",
      borderRadius: "100%",
    },
    [theme.breakpoints.up("md")]: {
      boxShadow: "5px 2px 2px 1px rgba(0, 0, 255, .2)",
      height: "13rem!important",
      width: "13rem!important",
      borderRadius: "100%",
    },
  },

  navlink: {
    [theme.breakpoints.down("sm")]: {
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: 11,
      color: "black",
      fontWeight: 350,
    },
    [theme.breakpoints.between("md", "lg")]: {
      color: "black",
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: 13,
      fontWeight: 700,
      marginLeft: '0em',
      marginRight: '2em',
    },
    // [theme.breakpoints.up("md")]: {
    //   color: "black",
    //   textDecoration: "none",
    //   // textTransform: "uppercase",
    //   fontSize: 14,
    //   fontWeight:450
    // },
  },
  notFound: {
    textAlign: "center",
    marginTop: "2em",
    marginBottom: "5em",
  },
  center: {
    textAlign: "center",
    marginTop: "5em",
    marginBottom: "5em",
  },
  input: {
    color: "red",
  },
  title: {
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  visible: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      borderRadius: "25% 10%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      visibility: "visible",
      paddingTop: "3rem",
      borderRadius: "25% 10%",
    },
    [theme.breakpoints.up("md")]: {
      visibility: "visible",
      borderRadius: "25% 10%",
    },
  },
  link: {
    [theme.breakpoints.down("sm")]: {
      color: "black",
      textTransform: "uppercase",
      textDecoration: "none",
      fontSize: 10,
    },
    [theme.breakpoints.between("sm", "md")]: {},
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
  },
  text: {
    [theme.breakpoints.down("xs")]: {
      margin: "3rem",
      fontSize: 13,
      fontWeight: "bold",
    },
    [theme.breakpoints.up("xs")]: {
      margin: "3rem 0 3rem 4rem",
      fontSize: 20,
      fontWeight: "bold",
    },
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0.5rem",
      fontWeight: "bold",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: "1rem 2rem",
      fontWeight: "bold",
    },
  },
  round: {
    borderRadius: "150%",
  },
  cercle: {
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#fff",
      textAlign: "center",
      alignContent: "center",
      padding: 8,
      size: 7,
    },
    [theme.breakpoints.up("sm")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",

      textAlign: "center",
      alignContent: "center",
      padding: 20,
    },
  },

  youTube: {
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#ff0000",
      textAlign: "center",
      alignContent: "center",
      padding: 8,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#ff0000",
      textAlign: "center",
      alignContent: "center",
      padding: 20,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
  },
  twitter: {
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#1DA1F2",
      textAlign: "center",
      alignContent: "center",
      padding: 8,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#1DA1F2",
      textAlign: "center",
      alignContent: "center",
      padding: 20,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
  },
  facebook: {
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#4267B2",
      textAlign: "center",
      alignContent: "center",
      padding: 8,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#4267B2",
      textAlign: "center",
      alignContent: "center",
      padding: 20,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
  },
  linkedIn: {
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#0e76a8",
      textAlign: "center",
      alignContent: "center",
      padding: 8,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
    [theme.breakpoints.up("md")]: {
      width: "10rem",
      height: "10rem",
      borderRadius: "100%",
      backgroundColor: "#FFF",
      color: "#0e76a8",
      textAlign: "center",
      alignContent: "center",
      padding: 20,
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
    },
  },
  paragraphe: {
    [theme.breakpoints.down("xs")]: {
      padding: "0.1rem 1rem",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: "1rem 3rem 0rem 3rem",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
    },
  },
}));

export default useStyles;
