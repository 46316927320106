import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { Stack } from "@mui/material";

const Share = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      marginTop={1}
      display={"flex"}
      justifyContent={"center"}
    >
      <FacebookShareButton
        name="facebook"
        aria-label="facebook"
        quote="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
        hashtag="#menuiserie"
      >
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        aria-label="facebook messenger"
        name="facebook messenger"
        quote="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
        appId="487707519453839"
      >
        <FacebookMessengerIcon size={30} round={true} />
      </FacebookMessengerShareButton>
      <WhatsappShareButton
        aria-label="whatsapp"
        name="whatsapp"
        quote="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
      >
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton
        aria-label="twitter"
        name="twitter"
        title="Yohann Diffusion"
        url="https://yohanndiffusion.com/"
        hashtags="Menuiserie"
      >
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>
      <EmailShareButton
        aria-label="email"
        name="email_share"
        subject="Yohann Diffusion Menuiserie"
        url="https://yohanndiffusion.com/"
        hashtag="Menuiserie"
      >
        <EmailIcon size={30} round={true} />
      </EmailShareButton>
    </Stack>
  );
};

export default Share;
