import React from "react";
import { Box, Stack, Link, Typography } from "@mui/material";
import logo from "../../../Images/logo-yohann-diffusion.png";
import { LinkFooter } from "../../customed";
import "./footer.css";

const Footer = () => {
  return (
    <Box
      justifyContent={"center"}
      height={25}
      style={{
        backgroundColor: "#fff",
        color: "black",
        marginBottom: "0rem",
      }}
    >
      <Stack sx={{ textAlign: "center", alignItems: "center" }}>
        <div
          style={{
            flexDirection: "column",
            backgroundColor: "#fff",
            margin: "1.5em",
          }}
        >
          <img alt="Logo Yohann Diffusion" src={logo} id="logoFooter" />
        </div>
        <Typography fontSize={15} fontWeight={"bold"} pb={2}>
          Menuiserie Yohann Diffusion
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          spacing={{ xs: 3, md: 9 }}
          fontWeight={"bold"}
          justifyContent={"center"}
          mt={{ sm: 5, md: 2 }}
          mb={{ sm: 5, md: 2 }}
        >
          <Link href="/#intro">
            <LinkFooter>Nous connaître</LinkFooter>
          </Link>
          <Link href="/#services">
            <LinkFooter>Nos services</LinkFooter>
          </Link>
          <Link href="/#materiel">
            <LinkFooter>Notre matériel</LinkFooter>
          </Link>
          <Link href="/#contact">
            <LinkFooter>Nous contacter</LinkFooter>
          </Link>
          <Link href="/mentionslegales">
            <LinkFooter>Mentions Légales</LinkFooter>
          </Link>
        </Stack>
        <Stack p={{ xs: 3, sm: 2 }}>
          <Typography fontSize={13} color={"#800000"}>
            Made with passion by{" "}
            {/* <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/clémence-ménez/"
            > */}
            Clémence Ménez
            {/* </a> */}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Footer;
