import React from "react";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import { Divide } from "../customed";

const Intro = () => {
  return (
    <Stack
      color={"#fff8e7"}
      padding={{
        xs: "0.5em 0em 2rem 0em",
        sm: "4rem",
        md: "1rem 2rem",
        lg: "1rem 6rem",
      }}
      id="intro"
      style={{
        backgroundColor: "#800000",
        fontWeight: "bold",
      }}
    >
      <Divide textAlign="left">
        <Typography variant="h6">A propos de nous</Typography>
      </Divide>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        justifyContent={"center"}
        alignItems={"center"}
        alignContent={"center"}
        spacing={{ xs: 3, sm: 1, md: 3, lg: 2 }}
      >
        <Stack
          width={{ xs: 310, sm: 580, md: 1000, lg: 1200 }}
          height={{ xs: 250, sm: 400, md: 450 }}
          pt={{ xs: 5, sm: 2 }}
          pb={{ xs: 0, sm: 0 }}
        >
          <CardMedia
            className="reveal revealRollIn"
            component={"iframe"}
            height={400}
            width={600}
            boxshadow={
              "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
            }
            title="Vidéo Yohann Diffusion"
            m={{ xs: 2, sm: "1rem 0rem 0rem 0rem", md: 7 }}
            src="https://www.youtube.com/embed/NdZ0TQCSneM"
            allowFullScreen
          />
        </Stack>
        <Stack
          spacing={{ xs: 1, sm: 4, md: 2, lg: 1 }}
          direction={{ xs: "column", sm: "row", md: "row" }}
          alignItems={"center"}
          p={0.5}
          width={{ xs: "20rem", sm: "40em", md: "65rem", lg: "75em" }}
        >
          <Box fontSize={{ sm: 18, md: 18, lg: 19 }} p={2}>
            Créée en 2022, la menuiserie YOHANN DIFFUSION est implantée dans le
            quartier de Kintele à Brazzaville.
            <p>
              Dotée d'équipements de pointe, notre équipe (spécialistes
              qualifiés dans la menuiserie), permet, de la conception à la pose,
              de répondre aux besoins des particuliers et des professionnels
              dans les travaux de menuiserie intérieures et extérieures.
            </p>
          </Box>
          <Box
            className="reveal revealRight"
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
          >
            <Typography
              className="animate__animated animate__tada animate__slow animate__infinite"
              fontSize={{ xs: 11.5, sm: 11.5, md: 13.7 }}
              color="#592720"
              boxShadow={
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px"
              }
              fontWeight={"bolder"}
              width={{ xs: "5rem", sm: "5rem", md: "6rem" }}
              height={{ xs: "5rem", sm: "5rem", md: "6rem" }}
              borderRadius="100%"
              backgroundColor="#FDFBF9"
              textAlign="center"
              p={{ xs: 2, sm: 3 }}
              alignItems={"center"}
            >
              -10% sur toutes les ventes en ce moment !
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Intro;
