import React from "react";
import Services from "../Services/services";
import Form from "../Form/form";
import Materiel from "../Materiel/materiel";
import Intro from "../Intro/intro";
import Tarifs from "../Tarif/tarif";

const Home = () => {
  return (
    <>
      <Intro />
      <Services />
      <Materiel />
      <Tarifs />
      <Form />
    </>
  );
};

export default Home;
